import { useCollection } from "../../hooks/useCollection"
import { useParams } from "react-router-dom"
import { db } from "../../firebase/config"
import { collection, doc, deleteDoc, updateDoc, addDoc, query, where, getDoc, documentId } from "firebase/firestore"
import { useDocument } from "../../hooks/useDocument"

//import RecipesList from "../../components/RecipesList"

import "./Recipe.css"

export default function Recipe() {
    const { id } = useParams()
    console.log('id', id)
    //const { document } = useDocument('Recipes', id)
    //const getDoc(collection(db, "Recipes", id))
    // const docRef = doc(db, "Recipes", id);

    // const docSnap = getDoc(docRef);
    // const recipe = docSnap.data()

    //const { documents: recipes } = useCollection("Recipes")
    const { documents: recipe } = useCollection("Recipes", [documentId(), "==", id])
    console.log("recipe:", recipe)


    return (
        <>
            {recipe && recipe.map(r => (
                <div key={r.id} className="recipe">
                    <h1 className="page-title">{r.title}</h1>
                    <hr />
                    <h2 style={{marginTop: "40px", textDecoration: "underline"}}>Ingredients</h2>
                    <ul>
                        {r.ingredients && r.ingredients.map(ingredient =>
                            <li key={ingredient}>{ingredient}</li>
                        )}
                    </ul>
                    <h2 style={{marginTop: "40px", textDecoration: "underline"}}>Steps</h2>
                    <ul>
                        {r.steps && r.steps.map(step =>
                            <li key={step}>{step}</li>
                        )}
                    </ul>
                </div>
            ))}
            {/* <RecipesList recipes={recipes} /> */}
        </>
    )
}