import { useState } from "react"
import { db, doc } from "../firebase/config"
import { collection, addDoc, updateDoc } from "firebase/firestore"
import { Link } from "react-router-dom"

// styles
import "./RecipesList.css"

//import Create from "./Create"
import Checkbox from "./Checkbox"

export default function RecipesList({ recipes }) {
  //const { mode } = useTheme()

  //const [show, setShow] = useState(false)

  //   const togglePopup = () => {
  //     setShow(!show)
  //   }

  //   const openCgcWindow = card => {
  //     console.log("card:", card)
  //     if (window.confirm("Show this card on CGC?") === true) {
  //       window.open("https://www.cgccards.com/certlookup/" + card.certification)
  //     }
  //   }

  const [checked, setChecked] = useState(false)

  const handleChange = recipe => {
    //setChecked(!checked)
    setChecked(!recipe.favorite)

    const docRef = doc(db, "Recipes", recipe.id)

    const data = {
      favorite: !recipe.favorite
    }
    console.log("data:", data)

    updateDoc(docRef, data).then(docRef => {
      console.log("quarter updated")
    })
  }

    return (
        <div className="list recipes-list">
            {recipes && recipes.map(recipe => (
                <div key={recipe.id} className="card dark">
                    <span style={{ display: "flex" }}>
                        <p style={{ marginRight: "auto" }}>{recipe.title}</p>
                        {/* <Link style={{ marginRight: "auto" }}>{recipe.title}</Link> */}
                        <Checkbox
                            label="Favorite"
                            // value={checked}
                            onChange={() => handleChange(recipe)}
                            defaultValue={recipe.favorite}
                        />
                    </span>
                    <hr />
                    <p>{recipe.description}</p>
                    {/* <p>Ingredients</p>
                    <ul>
                        {recipe.ingredients && recipe.ingredients.map(ingredient =>
                            <li key={ingredient}>{ingredient}</li>
                        )}
                    </ul>

                    <p>Steps</p>
                    <ul>
                        {recipe.steps && recipe.steps.map(step =>
                            <li key={step}>{step}</li>
                        )}
                    </ul> */}
                    <Link to={`/recipes/${recipe.id}`}>Details</Link>
                </div>
            ))}
        </div>
    )
}
